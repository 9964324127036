import parse from 'html-react-parser'

const StartDescription = (description) => {
  const { props } = description
  return (
    <div className='flex-initial flex-grow flex flex-col items-center mt-12 tablet:mt-2 start-title start-transition second'>
      {parse(props)}
    </div>
  )
}

export default StartDescription
