import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const Finish = ({ finishData, goodbyeData, canRestart, onRestart }) => {
  const { t } = useTranslation('global')

  goodbyeData = goodbyeData.replace(/<[^>]*>/g, '').split("\r\n\r\n");

  useEffect(() => {
    //if (canRestart && !finishFromModal)
    setTimeout(() => {
      onRestart()
    }, 5000)
  }, [])

  return (
    <div className="flex-initial flex-grow justify-between flex flex-col mb-10">
      {finishData.length > 0  && <div className="flex-initial flex-grow flex flex-col tablet:justify-center items-center finish page-transition mt-10 tablet:-mt-[10rem]">
        {finishData.map((finishItem, index) => {
          return (
            <p className={`finish-text ${index === 0 ? 'first' : ''}`} key={finishItem.id}>{finishItem.text}</p>
          )
        })}
      </div>}
      {finishData.length === 0 &&
      <div className="flex-initial flex-grow flex flex-col tablet:justify-center items-center finish page-transition mt-10 tablet:-mt-[10rem]">
        <p className="finish-text first">{goodbyeData[0]}</p>
        <p className="finish-text">{goodbyeData[1]}</p>
      </div>
      }
      {canRestart && <button className="continue-btn" onClick={() => {onRestart()}}>
        {t('new_survey')}
      </button>}
    </div>
  )
}
