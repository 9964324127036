import { useTranslation } from 'react-i18next'
import Logo from 'ui/common/Logo'

export function Error ({showLogo}) {
  const { t } = useTranslation('global')
  return (
    <div className="h-screen flex flex-col justify-between items-center">
      {showLogo && <Logo/>}
      <div className="flex-grow flex flex-col  justify-center items-center pr-10 pl-10">
        <h1 className="mb-32 text-center opacity-50">{t('error_description')}</h1>
      </div>
    </div>
  )
}
