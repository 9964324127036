import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import * as Constants from './ui/utils/constants'

const root = ReactDOM.createRoot(document.getElementById('root'))

const pollApp = process.env.REACT_APP_ENTRY;

let domainUrl
let apiUrl
let type

switch(pollApp) {
  case 'customerCn':
    domainUrl = Constants.DOMAIN_URL_PRO_CN
    apiUrl = Constants.CUSTOMER_API_URI
    break
  case 'cornerBeauty':
    domainUrl = Constants.DOMAIN_URL_PRO
    apiUrl = Constants.CUSTOMER_CORNER_API_URI
    type = 21
    break
  default:
    domainUrl = Constants.DOMAIN_URL_PRO
    apiUrl = Constants.CUSTOMER_API_URI
    break
}


root.render(
  <App domainUrl={domainUrl} apiUrl={apiUrl} type={type} />
)
