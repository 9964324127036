import serviceIcon from '../../Assets/images/icon_service.png'
import sizesIcon from '../../Assets/images/icon_sizes.png'
import onlineIcon from 'Assets/images/icon_online.png'
import fittingRoomIcon from '../../Assets/images/icon_fitting_room.png'
import tillIcon from 'Assets/images/icon_till.png'
import * as Constants from './constants'

export const areas = !Constants.DEBUG ? {
  93: { key: 'service', icon: serviceIcon, rated: false },
  159: { key: 'sizes', icon: sizesIcon, rated: false },
  160: { key: 'online', icon: onlineIcon, rated: false },
  94: { key: 'fitting_room', icon: fittingRoomIcon, rated: false },
  95: { key: 'till', icon: tillIcon, rated: false },
  162: { key: 'collect', icon: onlineIcon, rated: false },
} : {
  //different Ids for areas in dev form
  118: { key: 'service', icon: serviceIcon, rated: false },
  119: { key: 'sizes', icon: sizesIcon, rated: false },
  120: { key: 'online', icon: onlineIcon, rated: false },
  121: { key: 'fitting_room', icon: fittingRoomIcon, rated: false },
  122: { key: 'till', icon: tillIcon, rated: false },
  123: { key: 'collect', icon: onlineIcon, rated: false }, //empty section
}
