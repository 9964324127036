import blackLogo from 'Assets/images/blackLogo.png'
import whiteLogo from 'Assets/images/whiteLogo.png'

const Logo = ({isWhiteLogo}) => {
  return (
    <div className='w-full flex flex-col justify-center items-center mt-20 small:mt-5 mb-5 tablet:mb-16'>
      <div className='h-[3.75rem] tablet:h-[5rem]'>
        <img src={isWhiteLogo ? whiteLogo : blackLogo} alt='logo' className='block h-full object-cover' />
      </div>
    </div>
  )
}

export default Logo
