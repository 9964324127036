import { useEffect, useState } from 'react'
import { CheckboxItem } from './CheckboxItem'

export function Checkbox ({ onAnswerChange, savedAnswers, question, isSmall }) {
  const savedAnswer = savedAnswers.find(answer => answer.question_id === question.id)
  const [values, setValues] = useState(savedAnswer.value ?? [])

  const manageValues = (answerId, selected) => {
    if (selected) {
      setValues([...values, answerId])
    } else {
      setValues(values.filter(id => id !== answerId))
    }
  }

  useEffect(() => {
    onAnswerChange(question.id, values, null)
  }, [values])

  return (
    <div className="flex-grow">
      <p className={`${isSmall ? 'mt-8' : 'mb-10'} small:mb-5 question-text`}>{question.text}</p>
      <div className="flex flex-col gap-4 items-center justify-around mt-6 small:mt-2 checkbox">
        {question.answers.map((answer) => (
            <CheckboxItem key={answer.id}
                          answer={answer}
                          onSelected={manageValues}
                          initialState={savedAnswer.value !== null ? !!savedAnswer.value.includes(answer.id) : false}
            />
          )
        )}
      </div>
    </div>
  )
}
