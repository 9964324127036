import { useEffect } from 'react'
import { Accessibility } from 'accessibility'

export default function AccessibilityMenu({ locale }) {
  useEffect(() => {
    const synth = window.speechSynthesis

    const getVoicesAsync = () =>
      new Promise((resolve) => {
        const voices = synth.getVoices()
        if (voices.length > 0) {
          resolve(voices)
        } else {
          synth.onvoiceschanged = () => resolve(synth.getVoices())
        }
      })

    const initializeAccessibility = async () => {
      try {
        const voices = await getVoicesAsync()
        const localeVoice = voices.find((voice) => voice.lang.includes(locale))

        if (localeVoice) {
          new Accessibility({
            modules: {
              bigCursor: false,
              readingGuide: false,
              disableAnimations: false,
              underlineLinks: false,
              textToSpeech: true,
              speechToText: false,
            },
            suppressDomInjection: false,
            language: {
              textToSpeechLang: localeVoice.lang,
            },
            textToSpeechVoice: localeVoice.name,
            session: {
              persistent: false
            }
          })
        } else {
          new Accessibility({
            modules: {
              bigCursor: false,
              readingGuide: false,
              disableAnimations: false,
              underlineLinks: false,
              textToSpeech: true,
              speechToText: false,
            },
            suppressDomInjection: false,
            session: {
              persistent: false
            },
            language: {
              textToSpeechLang: 'en-EN',
              speechToTextLang: 'en-EN',
            },
          })
        }
      } catch (error) {
        console.error('Error getting voices', error)
      }
    }

    initializeAccessibility().then(r => console.log('Accessibility initialized'))

  }, [locale])

  /// Remove accessibility on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Seleccionar específicamente el contenedor del menú de accesibilidad
      const menu = document.querySelector('._access-menu');

      console.log('menu', menu);
      // Verificar si el clic ocurrió fuera del menú
      if (menu && !menu.contains(event.target)) {
        menu.classList.add('close'); // Añadir la clase "close" para ocultar el menú
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return null;
}
