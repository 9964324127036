import { TailSpin } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'

export function OpacityLoader() {
  const { t } = useTranslation('global')

  return (
    <div className="loader h-full w-full flex flex-col justify-center items-center gap-4">
      <p className="text-2xl uppercase font-thin text-white">{t('sending')}</p>
      <TailSpin color="#fff"
                visible={true}
                height="40"
                width="40"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""/>
    </div>
  )
}
