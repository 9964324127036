import Rating from 'react-rating'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'ui/utils/utils'
import { StarOutlined } from 'ui/common/StarOutlined'
import { StarFilled } from 'ui/common/StarFilled'

export function NewAreaRateItem ({ savedAnswer, question, onAnswerChange }) {
  const { t } = useTranslation('global')

  const [rating, setRating] = useState(savedAnswer.value)
  const [comment, setComment] = useState(savedAnswer.comment)
  const [showInput, setShowInput] = useState(savedAnswer.comment !== null || savedAnswer.comment == '')
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const textareaRef = useRef(null)

  useEffect(() => {
    onAnswerChange(question.id, rating, comment)
  }, [rating, comment])

  useEffect(() => {
    const checkIsMobile = isMobile()
    setIsMobileDevice(checkIsMobile)
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  const handleRating = (rate) => {
    if (rate === rating) {
      setRating(null)
    } else {
      setRating(rate)
    }
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const handleUnfocus = () => {
    if(!comment) {
      setShowInput(false)
    }
  }

  const handleClickOutside = (e) => {
    if(textareaRef.current && !textareaRef.current.contains(e.target)) {
      handleUnfocus()
    }
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <Rating
        className="rating"
        initialRating={rating}
        onClick={handleRating}
        emptySymbol={<StarOutlined/>}
        fullSymbol={<StarFilled/>}
      />
      <div className="flex align-center justify-center w-full">
        {showInput
          ? <textarea
            ref={textareaRef}
            className="tellMore"
            value={comment ?? ''}
            id={question.id}
            rows={isMobileDevice ? 3 : 5}
            placeholder={question.description}
            onChange={handleCommentChange}
          />
          : <button className="whiteBtn" onClick={() => setShowInput(true)}>{question.description}</button>}
      </div>
    </div>
  )
}
