import StartDescription from './StartDescription'
import '../../styles/Start.css'
import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import { LanguageSelectorTab } from 'ui/pages/poll/components/LanguageSelectorTab'

const Start = ({ welcomeText, onStart, locale, onTabClicked }) => {
  const { t } = useTranslation('global')

  const handlers = useSwipeable({
    onSwipedUp: (event) => onStart(),
  })

  return (
    <div className="w-full h-full flex flex-col items-center justify-between">
      <LanguageSelectorTab locale={locale} onClick={onTabClicked}/>
      <div className="flex-initial flex-grow flex flex-col gap-8 tablet:justify-between relative max-w-[400px]">
        <StartDescription props={welcomeText}/>
        <div className="flex flex-col justify-center items-center mb-20 tablet:mb-10 start-transition third">
          <button {...handlers} className="uppercase flex flex-col justify-center items-center" onClick={onStart}>
            <svg className="arrows mb-4">
              <path className="a1" d="M0 30 L40 0 L80 30"/>
              <path className="a2" d="M0 50 L40 20 L80 50"/>
              <path className="a3" d="M0 70 L40 40 L80 70"/>
            </svg>
            <div className="start-text">
              <p className="a1 uppercase text-2xl tablet:text-3xl font-thin text-black">{t('start')}</p>
              <p className="a2 uppercase text-2xl tablet:text-3xl font-thin text-gray-300 -mt-2">{t('start')}</p>
              <p className="a3 uppercase text-2xl tablet:text-3xl font-thin text-gray-100 -mt-2">{t('start')}</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Start
