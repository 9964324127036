import { useState } from 'react'

export function CheckboxItem ({ answer, onSelected, initialState }) {
  const [selected, setSelected] = useState(initialState)

  const toggleSelected = () => {
    const newState = !selected
    setSelected(newState)
    onSelected(answer.id, newState)
  }

  return (
    <p className={`whiteBtn ${selected ? 'selected' : ''} btn-checkbox`} onClick={toggleSelected}>
      {answer.text}
    </p>
  )
}
