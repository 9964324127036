import { WorldIcon } from 'ui/common/icons/WorldIcon'

export function LanguageSelectorTab({locale, onClick}) {
  const localeShortCode = locale.split('-')[0]

  return (
    <a
      className="cursor-pointer absolute bottom-[40%] left-0 px-2 py-4 bg-black flex flex-col items-center gap-3 rounded-r-sm rounded-b-sm language-transition"
      onClick={onClick}
    >
      <p className="locale-text">{localeShortCode}</p>
      <WorldIcon />
    </a>
  )
}
