import { useEffect, useRef, useState } from 'react'
import Rating from 'react-rating'
import { areas } from 'ui/utils/ratingAreas'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'ui/utils/utils'
import { StarOutlined } from 'ui/common/StarOutlined'
import { StarFilled } from 'ui/common/StarFilled'

export function RateQuestion ({ onAnswerChange, savedAnswers, question, areaIcon }) {
  const { t } = useTranslation('global')
  const savedAnswer = savedAnswers.find(answer => answer.question_id == question.id)

  const [isMobileDevice, setIsMobile] = useState(false)
  const [rating, setRating] = useState(savedAnswer.value)
  const [comment, setComment] = useState(savedAnswer.comment)
  const [showInput, setShowInput] = useState(savedAnswer.comment !== null || savedAnswer.comment == '')
  const textareaRef = useRef(null)

  useEffect(() => {
    const checkIsMobile = isMobile()
    setIsMobile(checkIsMobile)
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  useEffect(() => {
    if (areaIcon) {
      for (let area in areas) {
        onAnswerChange(area, null, null)
      }
    }
    onAnswerChange(question.id, rating, comment)
  }, [rating, comment])

  const handleRating = (newRate) => {
    if (newRate === rating) {
      setRating(null)
    } else {
      setRating(newRate)
    }
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const handleUnfocus = () => {
    if(!comment) {
      setShowInput(false)
    }
  }

  const handleClickOutside = (e) => {
    if(textareaRef.current && !textareaRef.current.contains(e.target)) {
      handleUnfocus()
    }
  }

  return (
    <>
      <p className={`mb-10 small:mb-5 question-text ${areaIcon ? 'area-icon' : ''} ${areaIcon ? `${areaIcon}` : ''}`}>{question.text}</p>
      <div className="flex flex-col items-center mb-5">
        <Rating
          className="w-full rating"
          initialRating={rating}
          onClick={handleRating}
          emptySymbol={<StarOutlined />}
          fullSymbol={<StarFilled />}
        />
      </div>
      <div className="flex align-center justify-center">
        {showInput
          ? <textarea
            ref={textareaRef}
            className="tellMore"
            value={comment ?? ''}
            id={question.id}
            rows={isMobileDevice ? 3 : 7}
            placeholder={question.description}
            onChange={handleCommentChange}
            onBlur={handleUnfocus}
          />
          : <button className="whiteBtn" onClick={() => setShowInput(true)}>{question.description}</button>}
      </div>
    </>
  )
}
