import Logo from 'ui/common/Logo'
import { IoMdClose } from 'react-icons/io'
import Picker from 'react-mobile-picker'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function LanguageSelector ({ onClose, exit, supportedLangs, locale }) {
  const l = (locale.includes('zh')) ? locale : locale.substring(0, 2)
  console.log(l)
  const { t } = useTranslation('global')
  const [pickerValue, setPickerValue] = useState({ lang: l })
  const closeTimeoutRef = useRef(null)

  useEffect(() => {
    function preventDefault (e) {
      e.preventDefault()
    }

    const closeComponent = () => {
      onClose(pickerValue['lang'])
    }

    const startCloseTimeout = () => {
      closeTimeoutRef.current = window.setTimeout(closeComponent, 3000)
    }

    const clearCloseTimeout = () => {
      if (closeTimeoutRef.current) {
        window.clearTimeout(closeTimeoutRef.current)
      }
    }

    const resetCloseTimeout = () => {
      clearCloseTimeout()
      startCloseTimeout()
    }

    const onTouchStart = () => {
      clearCloseTimeout()
    }

    const onTouchMove = () => {
      clearCloseTimeout()
    }

    const onTouchEnd = () => {
      resetCloseTimeout()
    }

    // start if there is no touch event
    startCloseTimeout()

    document.body.addEventListener('touchstart', onTouchStart)
    document.body.addEventListener('touchmove', onTouchMove)
    document.body.addEventListener('touchend', onTouchEnd)
    document.body.addEventListener('mousedown', onTouchEnd)
    document.body.addEventListener('touchmove', preventDefault, { passive: false })

    return () => {
      clearCloseTimeout()
      document.body.removeEventListener('touchstart', onTouchStart)
      document.body.removeEventListener('touchmove', onTouchMove)
      document.body.removeEventListener('touchend', onTouchEnd)
      document.body.removeEventListener('mousedown', onTouchEnd)
      document.body.removeEventListener('touchmove', preventDefault)
    }
  }, [onClose, pickerValue])

  const onTextClicked = (selected) => {
    if (selected) onClose(pickerValue['lang'])
  }

  return (
    <div className={`w-full h-dvh absolute top-0 left-0 bg-black flex flex-col items-center slide-in z-10 ${exit ? 'exit' : ''}`}>
      <IoMdClose className="absolute top-5 right-5 text-white text-3xl cursor-pointer" onClick={() => onClose(pickerValue['lang'])}/>
      <Logo isWhiteLogo={true}/>
      <h1 className="mt-8 tablet:mt-[4rem] language-title">{t('select_language')}</h1>
      <div className="mt-16 w-full small:mt-5 tablet:mt-[5rem] wheel-container">
        <Picker value={pickerValue} onChange={setPickerValue} wheelMode="natural" itemHeight="65" height="350">
          <Picker.Column key="lang" name="lang">
            {Object.keys(supportedLangs).map(name => (
              <Picker.Item key={name} value={name} selected={name === pickerValue['lang']}>
                {({ selected }) => (
                  <a className={`${selected ? '' : 'unselected'} wheel-text`} onClick={() => onTextClicked(selected)}>{supportedLangs[name].toUpperCase()}</a>
                )
                }
              </Picker.Item>
            ))}
          </Picker.Column>
        </Picker>
      </div>
    </div>
  )
}
