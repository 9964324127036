import loader from '../../Assets/images/loading.gif'

export function Loader () {
  return (
    <div className="w-screen h-dvh flex justify-center align-center">
      <div className="w-20 self-center">
        <img src={loader} alt="loading gif" className="size-auto"/>
      </div>
    </div>
  )
}
