import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function YesNoItem ({ onSelected, initialState }) {
  const [selected, setSelected] = useState(initialState)

  const { t } = useTranslation('global')

  const toggleSelected = (value) => {
    // If yes, toggle yes and set no to false
    setSelected(value)
    onSelected(value)
  }

  return (
    <div className="flex gap-4 items-center justify-between mt-6 small:mt-2 checkbox">
      <p className={`whiteBtn ${selected === 1 ? 'selected' : ''} yesNo`} onClick={() => toggleSelected(1)}>
        {t('yes')}
      </p>
      <p className={`whiteBtn ${selected === 0 ? 'selected' : ''} yesNo`} onClick={() => toggleSelected(0)}>
        {t('no')}
      </p>
    </div>

  )
}
