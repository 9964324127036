import { areas } from 'ui/utils/ratingAreas'
import { useTranslation } from 'react-i18next'
import { NewAreaRateItem } from 'ui/pages/poll/components/question_types/NewAreaRateItem'
import { useEffect, useRef, useState } from 'react'

export function NewAreaRate ({ question, areasData, savedAnswers, onAnswerChange }) {
  const { t } = useTranslation('global')
  const itemRefs = useRef([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleAnswerChange = (questionId, answer, comment) => {
    onAnswerChange(questionId, answer, comment);
    const currentIndex = areasData.findIndex(area => area.questions[0].id === questionId);
    if (currentIndex < areasData.length - 1 && !isFirstRender) {
      const nextItem = itemRefs.current[currentIndex + 1];
      if (nextItem) {
        nextItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    setIsFirstRender(false);
  };

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, areasData.length);
  }, [areasData]);

  return (
    <div className="mb-8 ">
      <p className="mb-10 small:mb-5 small:mb-8 question-text">{question.text}</p>
      <div className="flex flex-col gap-10 items-start justify-start mt-6 small:mt-2">
        {areasData.map((areaData, index) => {
          const area = areas[areaData.id]
          const savedAnswer = savedAnswers.find(answer => answer.question_id == areaData.questions[0].id)

          return (
            <div key={areaData.id} ref={el => itemRefs.current[index] = el} className="w-full flex flex-col gap-2 mb-5">
              <div className="flex items-center">
                <p className="text-base break-words font-light text-center">{t(area.key).toUpperCase()}</p>
                <div className="block max-w-[1.5em] small:w-[1.3em]">
                  <img src={area.icon} alt="icon" className="w-full object-cover"/>
                </div>
              </div>
              <NewAreaRateItem
                savedAnswer={savedAnswer}
                question={areaData.questions[0]}
                onAnswerChange={handleAnswerChange}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
